import React, { memo } from "react";
import { NavLink } from "react-router-dom";
// Material-UI imports
import { Paper, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Material-UI icon imports
import { ReactComponent as CompletedIcon } from "../../icons/icon_completed.svg";
import { ReactComponent as OverviewIcon } from "../../icons/icon_overview.svg";
import { ReactComponent as ScheduledIcon } from "../../icons/icon_scheduled.svg";
import { ReactComponent as StatementsIcon } from "../../icons/icon_statements.svg";
import { ReactComponent as DocumentsIcon } from "../../icons/icon_documents.svg";
//local imports
import FeatureFlag from "../FeatureFlag";

function Navigation(props) {
  const classes = useStyles();

  const menuItems = [
    {
      link: "/overview",
      text: "Overview",
      icon: <OverviewIcon className={classes.icon} />,
    },
    {
      link: "/transactions/scheduled",
      text: "Scheduled Future Transactions",
      icon: <ScheduledIcon className={classes.icon} />,
    },
    {
      link: "/transactions/completed",
      text: "Processed Transactions",
      icon: <CompletedIcon className={classes.icon} />,
    },
    {
      link: "/statements",
      text: "Statements",
      icon: <StatementsIcon className={classes.icon} />,
    },
    {
      link: "/document-uploads",
      text: "Documents",
      icon: <DocumentsIcon className={classes.icon} />,
    },
  ];

  const MenuItem = ({ item }) => {
    return (
      <Box className={classes.menuItem}>
        <NavLink
          to={item.link}
          className={classes.menuLink}
          activeClassName="active"
        >
          {item.icon}
          <Typography
            className={classes.iconTitle}
            component="small"
            align="center"
            gutterBottom
          >
            {item.text}
          </Typography>
        </NavLink>
      </Box>
    );
  };

  const menu = menuItems.map(function (item, key) {
    if (item.text.includes("Documents")) {
      return (
        <FeatureFlag key={item.link} flagName="DocumentUploadsEnabled">
          <MenuItem item={item} />
        </FeatureFlag>
      );
    } else {
      return <MenuItem item={item} key={item.link} />;
    }
  });

  return (
    <Paper elevation={0} square={true}>
      <nav role="navigation">{menu}</nav>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  menuLink: {
    textDecoration: "none",
    padding: theme.spacing(3, 3),
    display: "block",
    color: "#767676",
    "&:hover": {
      color: "#fff",
      background: theme.palette.primary.main,
    },
    "&.active": {
      color: "#fff",
      background: theme.palette.primary.dark,
    },
    "&:hover svg": {
      fill: "#fff",
    },
    "&.active svg": {
      fill: "#fff",
    },
  },
  menuItem: {
    width: "100%",
    boxSizing: "border-box",
  },
  icon: {
    display: "block !important",
    margin: "0 auto",
    width: "auto",
    height: "2em !important",
    fill: "#767676",
  },
  iconTitle: {
    lineHeight: 1.3,
    marginTop: theme.spacing(1),
    display: "block",
  },
}));

export const MemoizedNavigation = memo(Navigation);
