import React, { useContext } from "react";
import { Link } from "@material-ui/core";
import { BrandingContext } from "../../context/Branding";
import formatPhoneNumbers from "../../helpers/FormatPhoneNumbers";

export default function GetBrandPhoneLink(htmlTag) {
  const brand = useContext(BrandingContext);

  // Customer Support Phone Number
  const phoneNumber = formatPhoneNumbers(
    brand.phoneNumber.replace(/-/g, "").substring(1)
  );

  const link = `tel:+${brand.phoneNumber.replace(/-/g, "")}`;

  return (
    <Link href={link} color="primary" variant={htmlTag}>
      {phoneNumber}
    </Link>
  );
}
