import React from "react";
import {
  Box,
  makeStyles,
  Paper,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";

export default function DepositComplete() {
  const logo = `/globalholdings/images/mobileLogo.png`;
  const classes = useStyles();
  return (
    <>
      <div className={classes.userFlowBackground}>
        <Paper
          className={classes.container}
          style={{ padding: 5, paddingTop: 20 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={logo} alt="Logo" />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.messageBold}>
                Would you like to view your Deposit in your Global Account?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box style={{ textAlign: "center" }}>
                <Button
                  className={classes.loginButton}
                  style={{ marginBottom: 25 }}
                  variant="contained"
                  color="primary"
                  href="/"
                >
                  Log In
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.messageBold}>
                Need a Log In?
              </Typography>
              <Typography className={classes.message}>
                To set up your account access
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box style={{ textAlign: "center" }}>
                <Button
                  className={classes.loginButton}
                  variant="outlined"
                  color="primary"
                  href="/createuser "
                >
                  Start Here
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Setting up account access will allow you to:
                <ul>
                  <li>View balances</li>
                  <li>See transaction history</li>
                  <li>Download statements</li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  logo: {
    display: "block",
    width: "100%",
    maxWidth: 256,
    padding: theme.spacing(0, 0, 4),
    margin: "0 auto",
  },
  container: {
    margin: "0 auto",
    width: "95%",
    [theme.breakpoints.up("md")]: {
      width: 800,
    },
  },
  userFlowBackground: {
    background: "#eeeff1",
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },
  message: {
    fontSize: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  messageBold: {
    fontSize: "1rem",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  loginButton: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));
