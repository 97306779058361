import axios from "axios";
import { cacheAdapterEnhancer } from "axios-extensions";

const fetchWithCache = axios.create({
  adapter: cacheAdapterEnhancer(axios.defaults.adapter),
});

const fetchWithoutCache = axios.create({
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: false,
  }),
});

function fetchApi(method, url, data = {}, token = null, disabledCache = false) {
  return new Promise((resolve, reject) => {
    const fetchConfig = {
      method: method,
      url: url,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Authorization: token,
      },
    };

    if (disabledCache) {
      fetchWithoutCache(fetchConfig)
        .then((response) => {
          if (response.status === 200 || response.status === 204) {
            resolve(response);
          } else {
            throw new Error(`Error Code ${response.status}`);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      fetchWithCache(fetchConfig)
        .then((response) => {
          if (response.status === 200 || response.status === 204) {
            resolve(response);
          } else {
            throw new Error(`Error Code ${response.status}`);
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
}

export function fetchDebtSettlementApi(
  method,
  url,
  data = {},
  token = sessionStorage.getItem("legacy_token")
    ? `Bearer ${sessionStorage.getItem("legacy_token")}`
    : null
) {
  return fetchApi(method, url, data, token);
}

export function fetchGatewayApi(
  method,
  url,
  data = {},
  token = sessionStorage.getItem("access_token")
    ? `Bearer ${sessionStorage.getItem("access_token")}`
    : null
) {
  return fetchApi(method, url, data, token);
}

export function fetchGatewayApiNoCache(
  method,
  url,
  data = {},
  token = sessionStorage.getItem("access_token")
    ? `Bearer ${sessionStorage.getItem("access_token")}`
    : null
) {
  return fetchApi(method, url, data, token, true);
}
