import React, { Fragment } from "react";
// Material-UI imports
import { Grid, Typography, Paper, Box } from "@material-ui/core";
// Custom imports
import ChangePasswordForm from "../../components/SecuritySettingsForm/ChangePasswordForm";

export default function SecurityQuestions(props) {
  return (
    <Fragment>
      <Grid container spacing={1} justify="center">
        <Grid item xs={12} lg={8}>
          <Box mt={6}>
            <Paper square={true}>
              <Box pb={2}>
                <Typography
                  className="moduleTitle"
                  variant="h6"
                  component="h1"
                  gutterBottom
                >
                  Change your temporary password
                </Typography>
                <ChangePasswordForm />
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}
