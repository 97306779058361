import React, { Fragment } from "react";
// Material-UI imports
import { Grid, Paper, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Custom imports
import { MemoizedHeader as Header } from "../../components/Header";
import { MemoizedFooter as Footer } from "../../components/Footer";
import { MemoizedNavigation as Navigation } from "../../components/Navigation";
import { userIsAuthenticated } from "../../services/Authentication";

export default function Layout(props) {
  const classes = useStyles();

  if (!userIsAuthenticated()) {
    return <div className={classes.scroller}>{props.children}</div>;
  } else {
    return (
      <Fragment>
        <Header disabled={false} />
        <Paper elevation={0}>
          <Grid container spacing={0}>
            <Hidden smDown>
              <Grid item className={classes.mainNavigation}>
                <Navigation />
              </Grid>
            </Hidden>
            <Grid item className={classes.mainContent} role="main">
              {props.children}
            </Grid>
            <Grid item component="footer" className={classes.footer}>
              <Footer />
            </Grid>
          </Grid>
        </Paper>
      </Fragment>
    );
  }
}

const useStyles = makeStyles(theme => ({
  menuLink: {
    color: theme.palette.primary.main,
    textDecoration: "none"
  },
  mainNavigation: {
    flex: "0 0 200px",
    height: "calc(100% - 24px)",
    borderBottom: "1px solid #eee",
    borderTop: "1px solid #eee"
  },
  mainContent: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "calc(100% - 200px)",
    background: "#eeeff1",
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100% - 24px)"
  },
  footer: {
    height: 24,
    width: "100%",
    paddingLeft: theme.spacing(2)
  },
  scroller: {
    height: "100%",
    width: "100%",
    overflow: "auto"
  }
}));
