import React, { Fragment, useState } from "react";
// Material-UI imports
import {
  Typography,
  Box,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
// Custom imports
import {
  hasSpecialCharacters,
  hasUpperCaseLetter,
  hasMinCharacters,
  characterCountEqualsTo,
} from "../../services/Validation";
import { logOut } from "../../services/Authentication";
import {fetchDebtSettlementApi} from "../../services/FetchApi";
import getApiEnvironmentConfig from "../../helpers/GetApiEnvironmentConfig";
import GetBrandPhoneLink from "../GetBrandPhoneLink";

export default function ChangePasswordForm() {
  const classes = useStyles();
  const [values, setValues] = useState({
    currentPassword: "",
    newPassword: "",
    verifyNewPassword: "",
    checked: false,
  });
  const [hasErrors, setHasErrors] = useState({
    currentPassword: false,
    newPassword: false,
    verifyNewPassword: false,
  });
  const [helperText, setHelperText] = useState({
    currentPassword: "",
    newPassword: "",
    verifyNewPassword: "",
  });
  /* This is a future development idea. 
  Highlighting each requirement that are met in green 
  and that are not met in red 
  as the user types in the password.
  const [uppercase, setUpperCase] = useState("");
  const [specialCharacter, setSpecialCharacter] = useState("");
  const [minCharacters, setMinCharacters] = useState("");
  */
  const [showPassword, setShowPassword] = useState("password");
  const [confirmation, setConfirmation] = useState({
    text: "",
    classname: "",
    ariaStatus: "status",
  });
  const phoneLink = GetBrandPhoneLink("p");

  const userName = sessionStorage.getItem("userName");
  let accountID = sessionStorage.getItem("AccountId");
  accountID = accountID.substring(accountID.length - 8);

  const onSubmit = (event) => {
    event.preventDefault();
    const api = `${getApiEnvironmentConfig()}/useraccess/passwordchange`;
    fetchDebtSettlementApi(
      "PUT",
      api,
      JSON.stringify({
        userName: userName,
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
        LastEightAccountId: accountID,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
      })
    )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.HasErrors === true) {
            setConfirmation({
              text: `${response.data.Errors[0].message}.`,
              classname: "error",
              ariaStatus: "alert",
            });
          } else {
            setConfirmation({
              text: "Your password was updated successfully.",
              classname: "success",
              ariaStatus: "status",
            });
          }
          if (sessionStorage.getItem("twoFactorStatus") === 0) {
            setTimeout(() => {
              logOut();
            }, 3000);
          } else {
            sessionStorage.setItem("tempPass", false);
            setTimeout(() => {
              window.location.assign("/overview");
            }, 2000);
          }
        } else {
          throw new Error(`Error Code ${response.status}`);
        }
      })
      .catch((error) => {
        if (error.response.data.HasErrors === true) {
          setConfirmation({
            text: `${error.response.data.Errors[0].Message}.`,
            classname: "error",
            ariaStatus: "alert",
          });
        } else {
          setConfirmation({
            text: `Something went wrong. Please contact us for support:`,
            classname: "error",
            phone: phoneLink,
            ariaStatus: "alert",
          });
        }
        setHasErrors({
          currentPassword: true,
          newPassword: true,
          verifyNewPassword: true,
        });
        setValues({
          currentPassword: "",
          newPassword: "",
          verifyNewPassword: "",
          checked: false,
        });
      });
  };

  const handleCheckedChange = (name) => (event) => {
    const checked = event.target.checked;
    setValues({ ...values, [name]: checked });
    checked === true ? setShowPassword("text") : setShowPassword("password");
  };

  const handleInputChange = (prop) => (event) => {
    const value = event.target.value;
    setValues({ ...values, [prop]: value });

    // Validation of verifyNewPassword field
    if (prop === "verifyNewPassword") {
      if (value !== values.newPassword) {
        setHelperText({
          ...helperText,
          [prop]: "New passwords must match.",
        });
        setHasErrors({ ...hasErrors, [prop]: true });
      } else if (characterCountEqualsTo(value, 0)) {
        setHelperText({
          ...helperText,
          [prop]: "Password is required.",
        });
        setHasErrors({ ...hasErrors, [prop]: true });
      } else {
        setHelperText({
          ...helperText,
          [prop]: "",
        });
        setHasErrors({ ...hasErrors, [prop]: false });
      }
    }
  };

  // Validation
  const onBlur = (prop, verifyNewPassword) => (event) => {
    const value = event.target.value;
    if (prop === "newPassword") {
      if (characterCountEqualsTo(value, 0)) {
        setHelperText({
          ...helperText,
          [prop]: "New password is required.",
        });
        setHasErrors({ ...hasErrors, [prop]: true });
      } else if (
        !hasMinCharacters(value, 8) ||
        !hasSpecialCharacters(value) ||
        !hasUpperCaseLetter(value)
      ) {
        setHelperText({
          ...helperText,
          [prop]: "Your password does not meet the requirements.",
        });
        setHasErrors({ ...hasErrors, [prop]: true });
      } else {
        if (
          value !== values.verifyNewPassword &&
          values.verifyNewPassword !== ""
        ) {
          setHelperText({
            ...helperText,
            [prop]: " ",
            verifyNewPassword: "Passwords must match.",
          });
          setHasErrors({
            ...hasErrors,
            verifyNewPassword: true,
            [prop]: false,
          });
        } else {
          setHelperText({
            ...helperText,
            [prop]: "",
            verifyNewPassword: " ",
          });
          setHasErrors({
            ...hasErrors,
            verifyNewPassword: false,
            [prop]: false,
          });
        }
      }
    }

    if (prop === "currentPassword") {
      if (characterCountEqualsTo(value, 0)) {
        setHelperText({
          ...helperText,
          [prop]: "Current password is required.",
        });
        setHasErrors({ ...hasErrors, [prop]: true });
      } else {
        setHelperText({
          ...helperText,
          [prop]: "",
        });
        setHasErrors({ ...hasErrors, [prop]: false });
      }
    }

    if (prop === "verifyNewPassword") {
      if (characterCountEqualsTo(value, 0)) {
        setHelperText({
          ...helperText,
          [prop]: "Password is required.",
        });
        setHasErrors({ ...hasErrors, [prop]: true });
      }
    }
  };

  const isButtonEnabled = () => {
    if (
      values.currentPassword.length > 0 &&
      values.newPassword.length > 0 &&
      values.verifyNewPassword.length > 0
    ) {
      if (Object.values(hasErrors).every((item) => item === false)) {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <Box px={2} pb={2}>
        <Typography
          className={classes.moduleSubTitle}
          variant="h5"
          component="h1"
        >
          Change Password
        </Typography>
      </Box>
      <form onSubmit={onSubmit}>
        <Box px={2}>
          <TextField
            id="currentPassword"
            name="currentPassword"
            label="Current Password"
            variant="outlined"
            margin="normal"
            value={values.currentPassword}
            helperText={helperText.currentPassword}
            className="ajaxLabel"
            onChange={handleInputChange("currentPassword")}
            onBlur={onBlur("currentPassword")}
            error={hasErrors.currentPassword}
            fullWidth={true}
            autoComplete="off"
            type={showPassword}
            InputLabelProps={{ shrink: true, required: false }}
            InputProps={{
              inputProps: { autoComplete: "off", maxLength: 64 },
            }}
            required={true}
          />
        </Box>
        <Box px={2}>
          <TextField
            id="newPassword"
            name="newPassword"
            label="New password"
            variant="outlined"
            margin="normal"
            helperText={helperText.newPassword}
            value={values.newPassword}
            className="ajaxLabel"
            onChange={handleInputChange("newPassword")}
            onBlur={onBlur("newPassword")}
            error={hasErrors.newPassword}
            fullWidth={true}
            autoComplete="off"
            type={showPassword}
            InputLabelProps={{ shrink: true, required: false }}
            InputProps={{
              inputProps: { autoComplete: "off", maxLength: 64 },
            }}
            required={true}
          />
          <p className={classes.initialHelper}>
            Your Password should include one uppercase letter, one special
            character, and should be at least 8 characters in length.
          </p>
          {/*This is a future development idea
          <ul className={classes.passwordHelper}>
            <li className={uppercase}> include one uppercase letter</li>
            <li className={specialCharacter}> include one special character</li>
            <li className={minCharacters}>
              be at least 8 characters in length
            </li>
          </ul>*/}
        </Box>
        <Box px={2}>
          <TextField
            id="verifyNewPassword"
            name="verifyNewPassword"
            label="Verify New Password"
            variant="outlined"
            margin="normal"
            helperText={helperText.verifyNewPassword}
            value={values.verifyNewPassword}
            className="ajaxLabel"
            onChange={handleInputChange("verifyNewPassword")}
            onBlur={onBlur("verifyNewPassword")}
            error={hasErrors.verifyNewPassword}
            fullWidth={true}
            autoComplete="off"
            type={showPassword}
            InputLabelProps={{ shrink: true, required: false }}
            InputProps={{
              inputProps: { autoComplete: "off", maxLength: 64 },
            }}
            required={true}
          />
        </Box>
        <Box px={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.checked}
                color="primary"
                onChange={handleCheckedChange("checked")}
                value="checked"
              />
            }
            label="Show passwords"
          />
        </Box>
        <Box m={2} className={classes.aligncenter}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isButtonEnabled()}
          >
            Update password
          </Button>
        </Box>
      </form>
      <Box px={2}>
        <Typography
          variant="body1"
          className={confirmation.classname}
          role={confirmation.ariaStatus}
        >
          {confirmation.text}
          {confirmation.phone && <div>{confirmation.phone}</div>}
        </Typography>
      </Box>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  moduleSubTitle: {
    borderBottom: "1px dashed #eee",
  },
  passwordHelper: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.75rem",
    lineHeight: "1.3em",
    marginTop: 0,
  },
  initialHelper: {
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.54)",
    lineHeight: "1em",
    padding: theme.spacing(0, 1.5),
    margin: 0,
  },
}));
