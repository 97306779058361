import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import getBrandingConfig from "./helpers/GetBrandingConfig";
import App from "./containers/App";
import { BrandingContext } from "./context/Branding";
import {fetchGatewayApi} from "./services/FetchApi";
import replaceTitle from "./helpers/ReplaceTitle";
import replaceFavicon from "./helpers/ReplaceFavicon";
import detectKeyboardUser from "./helpers/DetectKeyboardUser";
import hexToRgba from "./helpers/HexToRgba";
import "./styles.css";
import getApiGatewayConfig from "./helpers/GetApiGatewayConfig";
require("typeface-poppins");

replaceTitle();
replaceFavicon();

const api = `${getApiGatewayConfig()}/Branding/${getBrandingConfig()}/`;

fetchGatewayApi("GET", api)
  .then(response => {
    if (response.status === 200) {
      const theme = createMuiTheme({
        palette: {
          primary: {
            main: `#${response.data.color1}`,
            light: "#fff",
            dark: `#${response.data.color3}`,
            gray: `#${response.data.color4}`
          },
          secondary: {
            main: `#${response.data.color2}`
          }
        },
        typography: {
          fontFamily: [
            "Poppins",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
          ].join(",")
        },
        props: {
          MuiButtonBase: {
            disableRipple: true
          }
        },
        overrides: {
          MuiButton: {
            root: {
              textTransform: "none",
              padding: "8px 32px",
              borderRadius: "20px",
              "&.hover": {
                backgroundColor: response.data.color3
              }
            },
            text: {
              textTransform: "none"
            },
            textPrimary: {
              "&:hover": {
                color: `#${response.data.color3}`,
                backgroundColor: "transparent"
              }
            }
          },
          MuiAppBar: {
            colorDefault: {
              backgroundColor: "#ffffff"
            }
          },
          MuiDialog: {
            paper: {
              width: "100%"
            }
          },
          MuiDialogContent: {
            root: {
              minWidth: 320
            }
          },
          MuiDialogTitle: {
            root: {
              padding: "16px 40px",
              borderBottom: "1px solid #eee"
            }
          },
          MuiTabs: {
            indicator: {
              display: "none"
            }
          },
          MuiTab: {
            root: {
              flexShrink: 1
            },
            textColorInherit: {
              opacity: 1
            }
          },
          MuiTableCell: {
            root: {
              padding: "14px 16px"
            }
          },
          MuiTablePagination: {
            toolbar: {
              flexWrap: "wrap"
            }
          },
          MuiCollapse: {
            entered: {
              paddingBottom: "16px"
            },
            wrapperInner: {
              lineHeight: "1.7"
            }
          },
          MuiTypography: {
            h3: {
              fontSize: "2.2rem"
            },
            h4: {
              fontSize: "1.6rem"
            },
            h5: {
              fontSize: "1.1rem"
            },
            h6: {
              fontSize: "1.1rem",
              fontWeight: "300",
              background: `#${response.data.color1}`,
              color: "#fff",
              padding: "12px 24px"
            },
            subtitle1: {
              fontWeight: "500"
            }
          },
          MuiExpanded: {
            backgroundColor: hexToRgba(response.data.color1, 0.2)
          },
          MuiMenuItem: {
            root: {
              paddingTop: 0,
              paddingBottom: 0
            }
          }
        }
      });
      window.addEventListener("keydown", detectKeyboardUser);
      render(
        <ThemeProvider theme={theme}>
          <BrandingContext.Provider value={response.data}>
            <App />
          </BrandingContext.Provider>
        </ThemeProvider>,
        document.getElementById("root")
      );
    } else {
      throw new Error(`Error Code ${response.status}`);
    }
  })
  .catch(error => {
    console.log(error);
  });
