export default function replaceFavicon() {
    const favicon = document.getElementById("favicon");

    switch (window.location.hostname.toLowerCase()) {
        case "paysmith.net":
        case "myaccount.paysmith.net":
        case "dev.myaccount.paysmith.net":
        case "qa.myaccount.paysmith.net":
            favicon.href = "/paysmith/images/favicon.ico";
            break;
        case "paymentconcepts.com":
        case "myaccount.paymentconcepts.com":
        case "dev.myaccount.paymentconcepts.com":
        case "qa.myaccount.paymentconcepts.com":
            favicon.href = "/paymentconcepts/images/favicon.ico";
            break;
        case "trans2pay.com":
        case "myaccount.trans2pay.com":
        case "dev.myaccount.trans2pay.com":
        case "qa.myaccount.trans2pay.com":
            favicon.href = "/trans2pay/images/favicon.ico";
            break;
        case "globalclientsolutions.com":
            favicon.href = "/globalclientsolutions/images/favicon.ico";
            break;
        default:
            favicon.href = "/globalholdings/images/favicon.ico";
    }
}
