import {
  format,
  addWeeks,
  addYears,
  differenceInDays,
  differenceInBusinessDays,
  subWeeks,
  startOfMonth,
  subMonths,
  endOfMonth,
  getYear,
  subYears,
  isValid,
  isDate,
  parse,
} from "date-fns";

export const isInstantiable = function (dateParam) {
  return !!(function (d) {
    return d !== "Invalid Date" && !isNaN(d);
  })(new Date(dateParam));
};

export const isValidDate = (dateParam) => {
  return isValid(dateParam);
};

export const isParseableDate = (dateParam) => {
  return isDate(dateParam);
};

export const getToday = () => {
  const todaysDate = new Date();
  const formattedDate = format(todaysDate, "MM/dd/yyyy");
  return formattedDate;
};

export const getThisYear = () => {
  const date = new Date();
  const thisYear = getYear(date);
  return thisYear;
};

export const getLastYear = () => {
  const date = new Date();
  const lastYear = subYears(date, 1);
  return lastYear;
};

export const getStartDate = () => {
  const date = getLastYear();
  const formattedDate = format(date, "yyyy-MM");
  return formattedDate;
};

export const getEndDate = (dateParam) => {
  const date = dateParam ? dateParam : new Date();
  const lastMonth = subMonths(date, 1);
  const formattedDate = format(lastMonth, "yyyy-MM");
  return formattedDate;
};

export const getSpecialMonthFormat = (dateParam) => {
  const date = dateParam ? new Date(dateParam + "-01T00:00:01") : "";
  const valid = isValidDate(date);
  const formattedDate = valid === true ? format(date, "MMM yyyy") : "";
  return formattedDate;
};

export const getNextWeeksDate = () => {
  const nextWeeksDate = addWeeks(new Date(), 1);
  const formattedDate = format(nextWeeksDate, "MM/dd/yyyy");
  return formattedDate;
};

export const getLastWeeksDate = () => {
  const lastWeeksDate = subWeeks(new Date(), 1);
  const formattedDate = format(lastWeeksDate, "MM/dd/yyyy");
  return formattedDate;
};

export const getFirstOfTheMonth = (dateParam) => {
  const date = dateParam ? dateParam : new Date();
  const firstOfTheMonth = startOfMonth(date);
  const formattedDate = format(firstOfTheMonth, "MM/dd/yyyy");
  return formattedDate;
};

export const getFirstDayOfLastMonth = () => {
  const lastMonth = subMonths(new Date(), 1);
  const formattedDate = format(lastMonth, "MM/dd/yyyy");
  const firstDayOfLastMonth = getFirstOfTheMonth(new Date(formattedDate));
  return firstDayOfLastMonth;
};

export const getFirstDayOfLastThreeMonths = () => {
  const lastMonth = subMonths(new Date(), 3);
  const formattedDate = format(lastMonth, "MM/dd/yyyy");
  const firstDayOfLastThreeMonths = getFirstOfTheMonth(new Date(formattedDate));
  return firstDayOfLastThreeMonths;
};

export const getLastDayOfLastMonth = () => {
  const lastMonth = subMonths(new Date(), 1);
  const lastDateOfMonth = format(endOfMonth(lastMonth), "MM/dd/yyyy");
  return lastDateOfMonth;
};

export const formatDate = (dateParam, formatParam, isPartialYear) => {
  if (!dateParam) {
    return "";
  }
  const formatStyle = formatParam ? formatParam : "MM/dd/yyyy";
  let date = null;
  const validDate = isValidDate(dateParam);
  // check if this is unparseable date format from the database (e.g. 01-DEC-2022 12.05.08 AM)
  const parseableDate = isParseableDate(dateParam);
  // if we cant parse the date, we know it is probably a bad date format from the database
  if (!validDate && !parseableDate) {
    date = parseBadDate(dateParam, isPartialYear);
  } else {
    date = new Date(dateParam);
  }
  const formattedDate = format(date, formatStyle);
  return formattedDate;
};

export const parseBadDate = (dateParam, isPartialYear) => {
  let date = new Date();
  // unfortunately, date-fns and JS date cannot handle dates that have an abbrev short month
  // check if it contains a dash
  const hasDashes = dateParam.indexOf("-");
  if (hasDashes !== -1) {
    // check if we can split the date into 3 parts
    let dParts = dateParam.split("-");
    if (dParts.length === 3) {
      // most likely this is a date in the wrong format:  01-DEC-2022 12.05.08 AM
      // convert the date parts into nominal values
      // now convert the day into a number
      const dayNum = parseInt(dParts[0]);
      let fullYearPart = getFullYearFromPartialYearPart(dParts[2]);
      // convert the year string into a number
      let yearNum = parseInt(fullYearPart);
      if (isPartialYear) {
        let yrp = dParts[2];
        let yp = "";
        try {
          yp = new Date().getFullYear().toString().substring(0, 2);
        } catch {
          yp = "20";
        }
        let fyr = `${yp}${yrp}`;
        yearNum = parseInt(fyr);
      }
      // now convert the month into a number
      // since month is represented as a short abbreviate month, we need to convert the short month into a long month
      const monthNum = getMonthNumber(dParts[1]);
      // just make sure we have parsed all the date parts to integers, so we dont throw any exceptions
      if (yearNum > 0 && monthNum > 0 && dayNum > 0) {
        // now, convert the date nums into an actual date object
        const sDay = dayNum.toString().padStart(2, "0");
        const sMonth = monthNum.toString().padStart(2, "0");
        const sYear = yearNum.toString().padStart(4, "0");
        const goodDate = `${sMonth}/${sDay}/${sYear}`;
        date = new Date(goodDate);
      }
    }
  } else {
    // this is to handle the epoch date scenario, in which date object treats: 1970-01-01 as an invalid date. this relates to the unit tests.
    date = new Date(dateParam);
  }
  return date;
};

export const getFullYearFromPartialYearPart = (yearPart) => {
  let result = "";
  // check if this part contains the time
  const hasTime = yearPart.indexOf(".");
  const hasAm = yearPart.indexOf("AM");
  const hasPm = yearPart.indexOf("PM");
  // this is getting specific here, but we need to make sure this is one of the bad date formats
  if (hasTime !== -1 && (hasAm !== -1 || hasPm !== -1)) {
    // truncate the time after the year value
    result = yearPart.substring(0, 4);
  }
  return result;
};

// This converts a short month name into a number: DEC -> 12
export const getMonthNumber = (month) => {
  let result = 0;
  if (typeof month === "string" && isNaN(month)) {
    month = month.toLowerCase();
    const months = {
      jan: 1,
      feb: 2,
      mar: 3,
      apr: 4,
      may: 5,
      jun: 6,
      jul: 7,
      aug: 8,
      sep: 9,
      oct: 10,
      nov: 11,
      dec: 12,
    };
    result = months[month];
  }
  return result;
};

export const formatPostDate = (dateParam) => {
  const formattedTransactionDate = formatDate(dateParam, "MM/d/yyyy");
  return formattedTransactionDate;
};

export const formatStatementDate = (dateParam) => {
  const formattedTransactionDate = formatDate(dateParam, "MM/dd/yyyy", true);
  return formattedTransactionDate;
};

export const formatDateFns = (dateValue, formatParam) => {
  const formatStyle = formatParam ? formatParam : "MM/dd/yyyy";
  let formattedDate = "";
  if (!dateValue || dateValue.length === 0) {
    return "";
  }
  if (typeof dateValue !== "string") {
    return dateValue;
  }
  let dateToFormat = new Date(dateValue);
  // Fixes an issue in which Date() computes the incorrect Day/Date number
  // when the passed in date is in the format: YYYY-MM-DD (e.g. 1959-05-19)
  // This is caused when the date seperators are "-" instead of "/" slash.
  if (dateValue.indexOf("-") !== -1) {
    const v1 = dateValue.indexOf("-");
    const v2 = dateValue.substring(0, v1);
    if (v2.length === 4) {
      const v4 = dateValue.indexOf("-", v1 + 1);
      const v5 = dateValue.substring(v1 + 1, v4);
      const v7 = dateValue.substring(v4 + 1);
      const verifyFormat = `${v2}/${v5}/${v7}`;
      dateToFormat = new Date(verifyFormat);
    }
  }
  try {
    formattedDate = format(dateToFormat, formatStyle);
  } catch (ex) {
    console.log(
      "Caught Invalid Date in formatDateFns() >>> dateValue >>> ",
      dateValue
    );
  }
  return formattedDate;
};

export const getDateByMonth = (monthValue) => {
  const newDate = new Date();
  newDate.setMonth(monthValue);
  return newDate;
};

// This function is used for date-fns testing and example purposes only.
export const test_date_fns_format = () => {
  const stPattysDay = new Date("2020/03/17");
  const formattedDate1 = format(stPattysDay, "MM/dd/yyyy");
  const formattedDate2 = format(stPattysDay, "MMMM dd, yyyy");
  const results = {
    formattedDate1: formattedDate1,
    formattedDate2: formattedDate2,
  };
  // console.log(formattedDate1);
  // => "03/17/2020"
  // console.log(formattedDate2);
  // => "March 17, 2020"
  return results;
};

// This function is used for date-fns testing and example purposes only.
export const test_date_fns_adding_subtracting_dates = () => {
  const stPattysDay = new Date("2020/03/17");
  const stPattysDayNextYear = addYears(stPattysDay, 1);
  const formattedDate = format(stPattysDayNextYear, "MMMM dd, yyyy");
  // console.log(formattedDate);
  // => "March 17, 2021"
  const results = {
    formattedDate: formattedDate,
  };
  return results;
};

export const test_date_fns_date_diffs = () => {
  const startDate = new Date("2020/01/01");
  const endDate = new Date("2020/12/24");
  const daysBetween = differenceInDays(endDate, startDate);
  const workdaysBetween = differenceInBusinessDays(endDate, startDate);

  // console.log(daysBetween);
  // => 358

  // console.log(workdaysBetween);
  // => 256

  const results = {
    daysBetween: daysBetween,
    workdaysBetween: workdaysBetween,
  };

  return results;
};

export const extractDate = (dateTimeStr) => {
  if (!dateTimeStr) {
    return "";
  }
  const date = parse(dateTimeStr, "MM/dd/yyyy h:mm:ss a", new Date());
  const formattedDate = format(date, "MM/dd/yyyy");

  return formattedDate;
};

export const ifTextNull = (text) => {
  return text ? text : "";
};
