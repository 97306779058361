export default function replaceTitle() {
  switch (window.location.hostname) {
    case "paysmith.com":
      document.title = "PaySmith - My Account";
      break;
    case "paymentconcepts.com":
      document.title = "Payment Concepts - My Account";
      break;
    case "trans2pay.com":
      document.title = "Trans2Pay - My Account";
      break;
    case "globalclientsolutions.com":
      document.title = "Global Client Solutions - My Account";
      break;
    default:
      document.title = "Global Holdings - My Account";
  }
}
