export default function getApiGatewayConfig() {
  const hostname = window.location.hostname;
  //let apiBrand;
  //if (hostname.indexOf("ghllc") !== -1) {
  //    apiBrand = "ghllc";
  //} else {
  //    apiBrand = "globalclientsolutions";
  //}

  try {
    //Use the Dev API for local development
    if (hostname.indexOf("localhost") !== -1) {
      return `https://dev.api.gateway.ghllc.com`;
    }
    //if (hostname.indexOf("localhost") !== -1) {
    //  return `http://localhost:18222`;
    //}
    // Dev API in dev environment
    else if (hostname.indexOf("dev") !== -1) {
      return `https://dev.api.gateway.ghllc.com`;
    }
    // QA API in QA environment
    else if (hostname.indexOf("qa") !== -1) {
      return `https://qa.api.gateway.ghllc.com`;
    }
    // Test
    else if (hostname.indexOf("test") !== -1) {
      return `https://test.api.gateway.ghllc.com`;
    }
    // Demo
    // @todo: We may need to build the API for Demo and then update this path.
    else if (hostname.indexOf("demo") !== -1) {
      return `https://qa.api.gateway.ghllc.com`;
    }
    // Prod fake
    else if (hostname.indexOf("myaccount") !== -1) {
      return `https://api.gateway.ghllc.com`;
    } else {
      throw new Error(
        "API could not be found. Please connect to this site via a URL instead of an IP address."
      );
    }
  } catch (error) {
    console.log(error);
  }
}
