import {
  Box,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  useMediaQuery,
  IconButton,
  SvgIcon,
  Grid,
  Modal,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { Fragment } from "react";
import { useContext } from "react";
import { useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { BrandingContext } from "../../context/Branding";
import getApiGatewayConfig from "../../helpers/GetApiGatewayConfig";
import {fetchDebtSettlementApi, fetchGatewayApi } from "../../services/FetchApi";
import formatPhoneNumbers from "../../helpers/FormatPhoneNumbers";
import { hasDigitsOnly } from "../../services/Validation";
import getApiEnvironmentConfig from "../../helpers/GetApiEnvironmentConfig";
import { useLocation } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { formatDateFns } from "../../helpers/FormatDates";
import { ReactComponent as BackArrow } from "../../icons/Back-Arrow.svg";
import { ReactComponent as HelpIcon } from "../../icons/Help-Icon.svg";

export default function Deposit() {
  const classes = useStyles();
  const errorClasses = textFieldErrorStyle();

  const brand = useContext(BrandingContext);
  const mobileLogo = `/globalholdings/images/mobileLogo.png`;

  let history = useHistory();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const logoSrc = "/globalholdings/images/letter.png";

  const [openAccount, setOpenAccount] = React.useState(false);
  const [openLookupAccount, setOpenLookupAccount] = React.useState(false);

  const [isDisabled, setIsDisabled] = React.useState(true);
  const [openError, setOpenError] = React.useState(false);

  const handleCloseAccount = () => {
    setOpenAccount(false);
  };

  const handleClickOpenAccount = () => {
    setOpenAccount(true);
  };

  const handleClickOpenLookupAccount = () => {
    setLookupValues((lookupValues) => ({ ...lookupValues, lastFourSSN: "" }));
    setLookupValues((lookupValues) => ({ ...lookupValues, zipcode: "" }));
    setLookupValues((lookupValues) => ({ ...lookupValues, dateOfBirth: "" }));
    setHasErrors((hasErrors) => ({ ...hasErrors, lastFourSSN: false }));
    setHasErrors((hasErrors) => ({ ...hasErrors, zipcode: false }));
    setHasErrors((hasErrors) => ({ ...hasErrors, dateOfBirth: false }));
    setHelperText((helperText) => ({ ...helperText, lastFourSSN: "" }));
    setHelperText((helperText) => ({ ...helperText, zipcode: "" }));
    setHelperText((helperText) => ({ ...helperText, dateOfBirth: "" }));
    setOpenLookupAccount(true);
  };
  const handleCloseLookupAccount = () => {
    setOpenLookupAccount(false);
  };

  const phoneNumber = formatPhoneNumbers(
    brand.phoneNumber.replace(/-/g, "").substring(1)
  );

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const [accountNumber, setAccountNumber] = useState(
    useQuery().get("accountId") || ""
  );
  const [accountNumberError, setAccountNumberError] = useState(false);
  const [accountNumberErrorMessage, setAccountNumberErrorMessage] =
    useState("");

  const [ssn, setSSN] = useState(useQuery().get("ssn") || "");
  const [ssnError, setSSNError] = useState(false);
  const [ssnErrorMessage, setSSNErrorMessage] = useState("");

  const [email, setEmail] = useState(useQuery().get("email") || "");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [amountErrorMessage, setAmountErrorMessage] = useState("");

  const [lookupValues, setLookupValues] = useState({
    lastFourSSN: "",
    zipcode: "",
    dateOfBirth: "",
  });

  const [helperText, setHelperText] = useState({
    dateOfBirth: "",
    zipcode: "",
    lastFourSSN: "",
  });

  const [hasErrors, setHasErrors] = useState({
    dateOfBirth: false,
    zipcode: false,
    lastFourSSN: false,
  });

  const [badMessage, setBadMessage] = useState(false);

  const handleInputChange = (prop) => (event) => {
    const value = event.target.value;
    setLookupValues({ ...lookupValues, [prop]: value });
  };

  const [proceedButtonDisabled, setProceedButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const Decimal_Regex = /[^0-9.]/g;
  const Decimal_Two_Places_Regex = /^(\d*\.{0,1}\d{0,2}$)/;
  const Email_Regex = /\S+@\S+\.\S+/;
  const Date_Of_Birth_Regex =
    /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/g;

  const [openAccountNumberMessage, setOpenAccountNumberMessage] =
    useState(false);
  const [openEmailAddressMessage, setOpenEmailAddressMessage] = useState(false);

  const handleAccountNumberChange = (value) => {
    if (isNaN(value)) {
      return;
    }

    setAccountNumber(value);
  };

  const handleAccountNumberBlur = () => {
    setOpenError(false);
    setBadMessage(false);
    if (accountNumber === "") {
      setAccountNumberError(true);
      setAccountNumberErrorMessage("Account Number is required.");
    } else if (accountNumber.length < 16) {
      setAccountNumberError(true);
      setAccountNumberErrorMessage("Account Number must be 16 digits.");
    } else {
      setAccountNumberError(false);
      setAccountNumberErrorMessage("");
    }

    checkProceedButton();
  };

  const handleSSNBlur = () => {
    setOpenError(false);
    setBadMessage(false);
    if (ssn === "") {
      setSSNError(true);
      setSSNErrorMessage("Last 4 of SSN is required.");
    } else if (ssn.length < 4) {
      setSSNError(true);
      setSSNErrorMessage("Last 4 of SSN must be 4 digits.");
    } else {
      setSSNError(false);
      setSSNErrorMessage("");
    }

    checkProceedButton();
  };

  const handleSSNChange = (value) => {
    if (isNaN(value)) return;
    setSSN(value);
  };

  const handleAmountChange = (value) => {
    let amountTemp = value.replace(Decimal_Regex, "");
    if (isNaN(amountTemp) && !amountTemp.includes(".")) setAmount("");

    // Allow any dollar amount with 2 decimal places.
    if (amountTemp.match(Decimal_Two_Places_Regex)) setAmount(amountTemp);
  };

  const handleAmountBlur = () => {
    if (amount === "") {
      setAmountError(true);
      setAmountErrorMessage("Amount cannot be empty.");
    } else {
      setAmountError(false);
      setAmountErrorMessage("");

      let amountTemp = parseFloat(amount.replace(Decimal_Regex, "")).toFixed(2);
      let amountTempFloat = parseFloat(amountTemp);
      let max = parseFloat(window.CONFIG.DEPOSIT_MAX);
      let min = parseFloat(window.CONFIG.DEPOSIT_MIN);

      if (amountTempFloat < min) {
        setAmountError(true);
        setAmountErrorMessage(
          `Amount cannot be less than $${min.toLocaleString("en", {
            minimumFractionDigits: 2,
          })}.`
        );
      } else if (amountTempFloat > max) {
        setAmountError(true);
        setAmountErrorMessage(
          `Amount cannot be greater than $${max.toLocaleString("en", {
            minimumFractionDigits: 2,
          })}.`
        );
      } else {
        setAmountError(false);
        setAmountErrorMessage("");
      }

      setAmount(
        `$${parseFloat(amountTemp).toLocaleString("en", {
          minimumFractionDigits: 2,
        })}`
      );
    }

    checkProceedButton();
  };

  const handleEmailAddressBlur = () => {
    if (Email_Regex.test(email)) {
      setEmailError(false);
      setEmailErrorMessage("");
    } else {
      setEmailError(true);
      setEmailErrorMessage("Invalid email address.");
    }

    setOpenEmailAddressMessage(false);
    checkProceedButton();
  };

  const onBlur = (prop) => (event) => {
    const value = event.target.value;

    if (prop === "zipcode") {
      if (value === "") {
        setHelperText({
          ...helperText,
          [prop]: "Zip code is required.",
        });
        setHasErrors({ ...hasErrors, [prop]: true });
      } else if (!hasDigitsOnly(value) || value.length !== 5) {
        setHelperText({
          ...helperText,
          [prop]: "Zip code must be 5 digits.",
        });
        setHasErrors({ ...hasErrors, [prop]: true });
      } else {
        setHelperText({
          ...helperText,
          [prop]: "",
        });
        setHasErrors({ ...hasErrors, [prop]: false });
      }
    }

    if (prop === "lastFourSSN") {
      if (value === "") {
        setHelperText({
          ...helperText,
          [prop]: "Last 4 of SSN is required.",
        });
        setHasErrors({ ...hasErrors, [prop]: true });
      } else if (!hasDigitsOnly(value) || value.length !== 4) {
        setHelperText({
          ...helperText,
          [prop]: "Last 4 of SSN must be 4 digits.",
        });
        setHasErrors({ ...hasErrors, [prop]: true });
      } else {
        setHelperText({
          ...helperText,
          [prop]: "",
        });
        setHasErrors({ ...hasErrors, [prop]: false });
      }
    }
  };

  useEffect(() => {
    if (brand.brandingIdentifier !== "globalholdings") {
      history.push("/");
    }
  });

  const onSubmit = (event) => {
    const api = `${getApiEnvironmentConfig()}/useraccess/AccountNumbers`;
    const formattedDate = formatDateFns(lookupValues.dateOfBirth);
    const data = {
      zipcode: lookupValues.zipcode,
      last4SSN: lookupValues.lastFourSSN,
      dateOfBirth: formattedDate,
    };

    fetchDebtSettlementApi("POST", api, JSON.stringify(data))
      .then((response) => {
        if (response.status === 200) {
          const availableAccounts = response.data.filter(
            (account) => account.registeredFlag === "N"
          );
          if (availableAccounts.length > 1) {
            setOpenError(true);
            setBadMessage(false);
          } else if (response.data[0].documentStatus !== "Y") {
            setOpenError(true);
            setBadMessage(true);
          } else {
            setSSN(lookupValues.lastFourSSN);
            setAccountNumber(response.data[0].accountId);
            setOpenError(false);
            setBadMessage(false);
          }

          if (response.data.hasErrors === true) {
            setOpenError(true);
            setBadMessage(false);
          } else if (response.data.hasWarnings === true) {
            setOpenError(true);
            setBadMessage(false);
          }
        } else {
          setOpenError(true);
          setBadMessage(false);
          throw new Error(`Error Code ${response.status}`);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            setOpenError(true);
            setBadMessage(false);
          } else {
            setOpenError(true);
            setBadMessage(false);
          }
        } else {
          setOpenError(true);
          setBadMessage(false);
        }
      });
    setOpenLookupAccount(false);

    setLookupValues((lookupValues) => ({ ...lookupValues, lastFourSSN: "" }));
    setLookupValues((lookupValues) => ({ ...lookupValues, zipcode: "" }));
    setLookupValues((lookupValues) => ({ ...lookupValues, dateOfBirth: "" }));

    setIsDisabled(true);
  };

  const handleProceedClick = () => {
    setProceedButtonDisabled(true);
    setIsLoading(true);
    const api = `${getApiGatewayConfig()}/Deposits/VerifyAccount?AccountId=${accountNumber}&Last4SSN=${ssn}`;

    fetchGatewayApi("GET", api)
      .then((response) => {
        if (response.status === 200) {
          if (
            response.data.businessType === "D" &&
            !response.data.closePending &&
            !response.data.closed &&
            response.data.documentStatus === "Y"
          ) {
            setOpenError(false);
            setBadMessage(false);
            getSecureHandOffUrl();
          } else {
            setProceedButtonDisabled(false);
            setIsLoading(false);
            setOpenError(true);
            setBadMessage(true);
          }
        } else {
          setProceedButtonDisabled(false);
          setIsLoading(false);
          setOpenError(true);
          setBadMessage(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          setProceedButtonDisabled(false);
          setIsLoading(false);
          setOpenError(true);
          setBadMessage(false);
        } else {
          setProceedButtonDisabled(false);
          setIsLoading(false);
          setOpenError(true);
          setBadMessage(false);
        }
      });
  };

  const getSecureHandOffUrl = () => {
    const api = `${getApiGatewayConfig()}/Deposits/SecureHandOff`;
    let amountTemp = amount.replace(Decimal_Regex, "");

    const data = {
      accountId: accountNumber,
      last4SSN: ssn,
      email: email,
      depositAmount: parseFloat(amountTemp),
    };

    fetchGatewayApi("POST", api, data)
      .then((response) => {
        if (response.status === 200) {
          window.location.href = response.data.payerExpressPortalUrl;
        } else {
          setProceedButtonDisabled(false);
          setIsLoading(false);
          setOpenError(true);
          setBadMessage(false);
          throw new Error(`Error Code ${response.status}`);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            setProceedButtonDisabled(false);
            setIsLoading(false);
            setOpenError(true);
            setBadMessage(false);
          } else {
            setProceedButtonDisabled(false);
            setIsLoading(false);
            setOpenError(true);
            setBadMessage(false);
          }
        } else {
          setProceedButtonDisabled(false);
          setIsLoading(false);
          setOpenError(true);
          setBadMessage(false);
        }
      });
  };

  const checkProceedButton = () => {
    let inputFieldsEmpty =
      accountNumber === "" || ssn === "" || email === "" || amount === "";

    let inputFieldsWithErrors =
      accountNumberError || ssnError || emailError || amountError;

    setProceedButtonDisabled(inputFieldsEmpty || inputFieldsWithErrors);
  };

  const handleAmountFocus = () => {
    setAmount(amount.replace(Decimal_Regex, ""));
  };

  // Call checkProceedButton after any of the error hooks are changed.
  // If not called inside a useEffect, these error hooks are not triggered on any of the onBlur events.
  useEffect(() => {
    checkProceedButton();
  }, [accountNumberError, ssnError, amountError, emailError]);

  useEffect(() => {
    if (
      lookupValues.dateOfBirth !== "" &&
      lookupValues.lastFourSSN !== "" &&
      lookupValues.zipcode !== "" &&
      !hasErrors.dateOfBirth &&
      !hasErrors.zipcode &&
      !hasErrors.lastFourSSN
    )
      setIsDisabled(false);
    else setIsDisabled(true);
  }, [lookupValues, hasErrors]);

  const handleDateOfBirthBlur = (prop) => {
    if (lookupValues.dateOfBirth.match(Date_Of_Birth_Regex) === null) {
      setHelperText({
        ...helperText,
        [prop]: "Invalid Date Format.",
      });
      setHasErrors({ ...hasErrors, [prop]: true });
    } else if (new Date(lookupValues.dateOfBirth) > new Date()) {
      setHelperText({
        ...helperText,
        [prop]: "Date of Birth cannot be a future date.",
      });
      setHasErrors({
        ...hasErrors,
        [prop]: true,
      });
    } else {
      setHelperText({
        ...helperText,
        [prop]: "",
      });
      setHasErrors({
        ...hasErrors,
        [prop]: false,
      });
    }
  };
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const { width, height } = getWindowDimensions();
  const [clientLogInScreenEnhancementFeatureFlag, setEnhancementFeatureFlag] =
    useState(true);

  useLayoutEffect(() => {
    const api = `${getApiEnvironmentConfig()}/featureflags/ClientLogInScreenEnhancement`;
    fetchDebtSettlementApi("GET", api)
      .then((response) => {
        if (response.status === 200) {
          var featureFlags = response.data || [];
          var clientLogInScreenEnhancementFeatureFlag = featureFlags.find(
            (flag) => flag.flagName === "ClientLogInScreenEnhancement"
          );

          if (!clientLogInScreenEnhancementFeatureFlag)
            setEnhancementFeatureFlag(false);

          setEnhancementFeatureFlag(
            clientLogInScreenEnhancementFeatureFlag.flagValue === "true"
          );
        } else {
          //fail silently
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className={classes.userFlowBackground}>
      <Box
        p={2}
        className={
          width < 375
            ? classes.goBackToLoginContainerSmallScreen
            : classes.goBackToLoginContainer
        }
      >
        {width < 600 && clientLogInScreenEnhancementFeatureFlag ? (
          <Button
            className={classes.goBackContainerRegularFontSize}
            startIcon={<BackArrow />}
            variant="text"
            color="primary"
            onClick={() => history.push("/")}
          >
            Back
          </Button>
        ) : clientLogInScreenEnhancementFeatureFlag ? (
          <Button
            className={classes.goBackContainerRegularFontSize}
            startIcon={<BackArrow />}
            variant="text"
            color="primary"
            onClick={() => history.push("/")}
          >
            Back to Login
          </Button>
        ) : (
          <></>
        )}
        <Button
          style={{ marginLeft: "auto", order: 2 }}
          className={classes.goBackContainerRegularFontSize}
          endIcon={<HelpIcon />}
          variant="text"
          color="primary"
          onClick={handleOpen}
        >
          One-Time Deposit Info
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box className={classes.infoModalStyle}>
            <IconButton onClick={handleClose} style={{ float: "right" }}>
              <CloseIcon />
            </IconButton>
            <Box
              className={
                width < 375 && height < 600
                  ? classes.infoModalItemStyleSmallScreen
                  : classes.infoModalItemStyle
              }
            >
              <h2>One-Time Deposit Info:</h2>
              <ul style={{ lineHeight: 2.0 }}>
                <li>
                  Expedited Deposits process every day.
                </li>
                <li>
                  The maximum deposit amount is $20,000. Your bank may have different 
                  transaction limits. You may want to confirm that your submission is 
                  within your bank's limits.
                </li>
                <li>
                  The deadline to cancel a same day debit card transaction is 5pm CST.
                </li>
                <li>
                  All debit cards will be accepted if issued by Visa, Mastercard, 
                  or Discover.
                </li>
              </ul>
            </Box>
          </Box>
        </Modal>
      </Box>
      <Box className={classes.container}>
        <Paper elevation={4} className={classes.paper}>
          {useMediaQuery(theme.breakpoints.up("md")) ? (
            <Fragment>
              <Box className={classes.leftLogo} />
              <Box p={4} className={classes.formBody}>
                <Box m={2} className={classes.titleColumn}>
                  <Typography className={classes.formInstructions}>
                    Use our payment processing platform to make a one-time
                    deposit.
                    <br /> <br />
                    Verify identity below:{" "}
                    <span>(all fields are required)</span>
                  </Typography>
                </Box>
                <Box className={classes.inputsColumn}>
                  <Box className={classes.fieldsRow}>
                    <Box m={2} marginRight={0}>
                      <TextField
                        error={accountNumberError}
                        helperText={accountNumberErrorMessage}
                        disabled={isLoading}
                        variant="outlined"
                        label="16 digit Global Account Number"
                        fullWidth={true}
                        value={accountNumber}
                        onChange={(e) =>
                          handleAccountNumberChange(e.target.value)
                        }
                        onBlur={() => handleAccountNumberBlur()}
                        inputProps={{
                          maxLength: 16,
                        }}
                        FormHelperTextProps={{
                          classes: {
                            root: errorClasses.root,
                          },
                        }}
                      />
                    </Box>
                    <Box m={2} marginRight={0}>
                      <TextField
                        error={ssnError}
                        helperText={ssnErrorMessage}
                        disabled={isLoading}
                        variant="outlined"
                        label="Last 4 digits of your SSN"
                        fullWidth={true}
                        value={ssn}
                        onChange={(e) => handleSSNChange(e.target.value)}
                        onBlur={() => handleSSNBlur()}
                        inputProps={{
                          maxLength: 4,
                        }}
                        FormHelperTextProps={{
                          classes: {
                            root: errorClasses.root,
                          },
                        }}
                      />
                    </Box>
                    <Box m={2} marginRight={0}>
                      <TextField
                        error={emailError}
                        helperText={emailErrorMessage}
                        disabled={isLoading}
                        variant="outlined"
                        label="Email Address"
                        fullWidth={true}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={() => handleEmailAddressBlur()}
                        FormHelperTextProps={{
                          classes: {
                            root: errorClasses.root,
                          },
                        }}
                      />
                    </Box>
                    <Box m={2} marginRight={0}>
                      <TextField
                        error={amountError}
                        helperText={amountErrorMessage}
                        disabled={isLoading}
                        variant="outlined"
                        label="Deposit Amount"
                        fullWidth={true}
                        value={amount}
                        onChange={(e) => handleAmountChange(e.target.value)}
                        onBlur={() => handleAmountBlur()}
                        onFocus={() => handleAmountFocus()}
                        FormHelperTextProps={{
                          classes: {
                            root: errorClasses.root,
                          },
                        }}
                      />
                    </Box>
                  </Box>

                  <Box className={classes.messagesRow}>
                    <Box m={2} className={classes.grayBox}>
                      <span className={classes.triangle}></span>
                      <Typography className={classes.message}>
                        Your 16 digit Account Number can be found in your
                        Welcome Kit.
                      </Typography>
                      <Button
                        variant="text"
                        color="primary"
                        className={classes.regularFontSize}
                        onClick={handleClickOpenAccount}
                      >
                        <Typography className={classes.messageLink}>
                          See example here
                        </Typography>
                      </Button>
                      <Dialog
                        fullScreen={fullScreen}
                        maxWidth="sm"
                        open={openAccount}
                        onClose={handleCloseAccount}
                        aria-labelledby="new-account-letter-dialog-title"
                        aria-describedby="new-account-letter-dialog-content"
                      >
                        <DialogTitle
                          id="forgot-password-dialog-title"
                          className={classes.dialogTitle}
                          disableTypography
                        >
                          <IconButton
                            className={classes.closeIcon}
                            onClick={handleCloseAccount}
                          >
                            <SvgIcon titleAccess="Go back to the login screen">
                              <CloseIcon />
                            </SvgIcon>
                          </IconButton>
                        </DialogTitle>
                        <DialogContent id="new-account-letter-dialog-content">
                          <Box py={2} className={classes.dialogContent}>
                            <img src={logoSrc} alt="Logo" />
                          </Box>
                        </DialogContent>
                      </Dialog>
                      <br />
                      <Typography className={classes.message}>
                        Can't find your Account Number?
                      </Typography>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClickOpenLookupAccount}
                      >
                        <Typography className={classes.messageLink}>
                          Look Up Account Number
                        </Typography>
                      </Button>
                    </Box>
                    <Box m={2} marginTop={-1} className={classes.grayBox}>
                      <span className={classes.triangle}></span>
                      <Typography className={classes.message}>
                        This is where we will send confirmation of your deposit.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box m={2} className={classes.buttonColumn}>
                  {!openError ? (
                    <Box> </Box>
                  ) : (
                    <Box>
                      <Box className={classes.whiteBox}>
                        {!badMessage ? (
                          <Typography className={classes.messageError}>
                            We cannot verify the account information provided.
                          </Typography>
                        ) : (
                          <Typography className={classes.messageError}>
                            We cannot accept a deposit for the account provided.
                          </Typography>
                        )}
                        <Typography className={classes.messageError}>
                          Please contact us for support.
                        </Typography>
                        <Typography className={classes.messageBlue}>
                          {phoneNumber}
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  <Box className={classes.proceedAction}>
                    <Box>
                      <Button
                        disabled={proceedButtonDisabled}
                        sx={{ borderRadius: 8 }}
                        variant="contained"
                        color="primary"
                        onClick={() => handleProceedClick()}
                      >
                        Proceed
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Fragment>
          ) : (
            <Fragment>
              <Box m={2}>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={mobileLogo} />
                </div>
                <Box m={1} p={1} textAlign="center">
                  <Typography className={classes.formInstructions}>
                    Use our payment processing platform to make a one-time
                    deposit.
                    <br /> <br />
                    Verify identity below:{" "}
                    <span>(all fields are required)</span>
                  </Typography>
                </Box>
                <Box sx={{ width: "100%" }} padding={1}>
                  <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                      <TextField
                        error={accountNumberError}
                        helperText={
                          accountNumberError ? accountNumberErrorMessage : ""
                        }
                        value={accountNumber}
                        variant="outlined"
                        fullWidth={true}
                        label="16 digit Global Account Number"
                        onFocus={() => {
                          setOpenAccountNumberMessage(true);
                          setOpenEmailAddressMessage(false);
                        }}
                        onChange={(e) =>
                          handleAccountNumberChange(e.target.value)
                        }
                        onBlur={() => handleAccountNumberBlur()}
                        inputProps={{
                          maxLength: 16,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box component="span">
                        <Box component="span" paddingLeft={1}>
                          Can't find your Account Number?
                        </Box>
                        <Button
                          fullWidth={true}
                          variant="outlined"
                          color="primary"
                          onClick={handleClickOpenLookupAccount}
                        >
                          <Typography className={classes.message}>
                            Look Up Account Number
                          </Typography>
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={ssnError}
                        helperText={ssnErrorMessage}
                        variant="outlined"
                        label="Last 4 digits of your SSN"
                        fullWidth={true}
                        value={ssn}
                        onChange={(e) => handleSSNChange(e.target.value)}
                        onBlur={() => handleSSNBlur()}
                        inputProps={{
                          maxLength: 4,
                        }}
                        onFocus={() => {
                          setOpenAccountNumberMessage(false);
                          setOpenEmailAddressMessage(false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={emailError}
                        helperText={emailError ? emailErrorMessage : ""}
                        fullWidth={true}
                        value={email}
                        variant="outlined"
                        label="Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                        onFocus={() => {
                          setOpenAccountNumberMessage(false);
                          setOpenEmailAddressMessage(true);
                        }}
                        onBlur={() => handleEmailAddressBlur()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box component="span">
                        This is where we will send confirmation of your deposit.
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={amountError}
                        helperText={amountErrorMessage}
                        fullWidth={true}
                        label="Amount Deposit"
                        variant="outlined"
                        value={amount}
                        onChange={(e) => handleAmountChange(e.target.value)}
                        onBlur={() => handleAmountBlur()}
                        onFocus={() => handleAmountFocus()}
                      />
                    </Grid>
                    {openError ? (
                      <Grid item xs={12}>
                        <Box>
                          <Box className={classes.whiteBox}>
                            {!badMessage ? (
                              <Typography className={classes.messageError}>
                                We cannot verify the account information
                                provided.
                              </Typography>
                            ) : (
                              <Typography className={classes.messageError}>
                                We cannot accept a deposit for the account
                                provided.
                              </Typography>
                            )}
                            <Typography className={classes.messageError}>
                              Please contact us for support.
                            </Typography>
                            <Typography className={classes.messageBlue}>
                              {phoneNumber}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    <Grid item xs={12}>
                      <Button
                        disabled={proceedButtonDisabled}
                        sx={{ borderRadius: 8 }}
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        onClick={() => handleProceedClick()}
                      >
                        Proceed
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Fragment>
          )}
        </Paper>
        <Dialog
          fullScreen={fullScreen}
          maxWidth="sm"
          open={openLookupAccount}
          onClose={handleCloseLookupAccount}
          aria-labelledby="new-account-letter-dialog-title"
          aria-describedby="new-account-letter-dialog-content"
        >
          <DialogTitle
            id="forgot-accoubt-dialog-title"
            className={classes.dialogTitle}
            disableTypography
          >
            <IconButton
              className={classes.closeIcon}
              onClick={handleCloseLookupAccount}
            >
              <SvgIcon titleAccess="Go back to the deposit screen">
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </DialogTitle>
          <DialogContent id="new-account-letter-dialog-content">
            <Box py={2} className={classes.dialogContent}>
              <Grid container justify="space-between" alignItems="flex-start">
                <Grid item xs={12}>
                  <Box p={2}>
                    <Typography className={classes.formInstructions}>
                      Can't find your Account Number?
                      <br />
                      Verify your identity below:{" "}
                      <span>(all fields are required)</span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box px={2}>
                    <TextField
                      id="zipcode"
                      name="zipcode"
                      label="Zip code"
                      variant="outlined"
                      margin="normal"
                      value={lookupValues.zipcode}
                      helperText={helperText.zipcode}
                      error={hasErrors.zipcode}
                      onBlur={onBlur("zipcode")}
                      onChange={handleInputChange("zipcode")}
                      fullWidth={true}
                      InputProps={{
                        inputProps: { maxLength: 5 },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        required: false,
                      }}
                      required={true}
                    />
                  </Box>
                  <Box px={2}>
                    <TextField
                      id="lastFourSSN"
                      name="lastFourSSN"
                      label="Last 4 digits of your SSN"
                      variant="outlined"
                      margin="normal"
                      value={lookupValues.last4SSN}
                      helperText={helperText.lastFourSSN}
                      error={hasErrors.lastFourSSN}
                      onBlur={onBlur("lastFourSSN")}
                      onChange={handleInputChange("lastFourSSN")}
                      fullWidth={true}
                      InputProps={{
                        inputProps: { maxLength: 4 },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        required: false,
                      }}
                      required={true}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box px={2} mt={3}>
                    <ReactInputMask
                      mask="99/99/9999"
                      placeholder="mm/dd/yyyy"
                      value={lookupValues.dateOfBirth}
                      onChange={handleInputChange("dateOfBirth")}
                      onBlur={() => handleDateOfBirthBlur("dateOfBirth")}
                    >
                      {() => (
                        <TextField
                          label="Date of Birth"
                          placeholder="mm/dd/yyyy"
                          helperText={helperText.dateOfBirth}
                          error={hasErrors.dateOfBirth}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </ReactInputMask>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box m={2}>
                    <Button
                      disabled={isDisabled}
                      variant="contained"
                      color="primary"
                      className={classes.nextButton}
                      onClick={() => onSubmit()}
                    >
                      Next
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: 10,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "center",
  },
  container: {
    margin: "0 auto",
    width: "95%",
    [theme.breakpoints.up("md")]: {
      width: 1000,
    },
  },
  paper: {
    overflow: "hidden",
    height: 600,
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      paddingBottom: 72,
    },
  },
  leftLogo: {
    width: 280,
    height: "100%",
    backgroundImage: "url(./globalholdings/images/globalLeft.png)",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  formInstructions: {
    fontWeight: 500,
    "& span": {
      color: "#888",
    },
    textAlign: "left",
  },
  formBody: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  userFlowBackground: {
    background: "#eeeff1",
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },
  grayBox: {
    background: "#ddd",
    borderRadius: 8,
    padding: theme.spacing(2, 1),
    position: "relative",
  },
  message: {
    fontSize: "1rem",
  },
  messageLink: {
    fontSize: "1rem",
    color: "#0e56ac",
  },

  whiteBox: {
    background: "#fff",
    borderRadius: 8,
    padding: theme.spacing(2, 2),
    position: "relative",
    flex: "1",
    display: "flex",
    flexDirection: "column",
  },
  messageError: {
    fontSize: "1rem",
    color: "red",
  },
  messageBlue: {
    fontSize: "1rem",
    color: "blue",
  },
  triangle: {
    position: "absolute",
    top: "1.2em",
    left: "0",
    transform: "translateX(-100%)",
    width: 0,
    height: 0,
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderRight: "10px solid #ddd",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  regularFontSize: {
    fontSize: "1em",
  },
  fieldsRow: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "space-around",
    marginRight: "5px",
  },
  messagesRow: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  titleColumn: {
    flex: "1",
    display: "flex",
    flexDirection: "column-reverse",
  },
  inputsColumn: {
    flex: "3",
    display: "flex",
    alignItems: "stretch",
  },
  buttonColumn: {
    flex: "1",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  proceedAction: {
    display: "flex",
    flexDirection: "row-reverse",
    flex: "1",
  },
  dialogTitle: {
    borderBottom: "none",
  },
  dialogContent: {
    display: "flex",
    justifyContent: "center",
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    "&:hover": {
      color: theme.palette.primary.main,
    },
    margin: theme.spacing(-1.5, 0),
  },
  nextButton: {
    position: "absolute",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    alignItems: "right",
  },
  goBackToLoginContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "calc(100% - 32px)",
    margin: "0 auto",
    maxWidth: 1024,
    marginTop: "-4em",
    fontSize: "1em",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
    },
  },
  goBackToLoginContainerSmallScreen: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "calc(100% - 32px)",
    margin: "0 auto",
    maxWidth: 1024,
    marginTop: "-4em",
    fontSize: "0.8em",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
    },
  },
  goBackContainerRegularFontSize: {
    fontSize: "1em",
    fontWeight: "600",
  },

  infoModalStyle: {
    display: "block",
    position: "absolute",
    top: "calc(55% - 32px)",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 32px)",
    maxWidth: 800,
    height: "auto",
    backgroundColor: "#fffcfc",
    opacity: 1,
    boxShadow: 3,
  },
  infoModalItemStyle: {
    marginTop: 35,
    marginLeft: 25,
    marginRight: 20,
    marginBottom: 20,
    color: "#313131",
  },
  infoModalItemStyleSmallScreen: {
    marginTop: 25,
    marginLeft: 10,
    marginRight: 20,
    marginBottom: 20,
    color: "#313131",
    fontSize: "0.8em",
  },
}));

const textFieldErrorStyle = makeStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: "-1.5rem",
  },
}));
