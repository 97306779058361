export function hasSpecialCharacters(string) {
  return /[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>@?]/g.test(string);
}

export function hasUpperCaseLetter(string) {
  return /[A-Z]/g.test(string);
}


export function hasMinCharacters(string, min) {
  return string.length >= min;
}

export function hasMaxCharacters(string, max) {
  return max >= string.length;
}

export function characterCountEqualsTo(string, number) {
  return number === string.length;
}

export function hasDigitsOnly(string) {
  return /^[0-9]+$/.test(string);
}

export function matchEmailFormat(string) {
  return /^([A-Za-z0-9_]+([-+.'][A-Za-z0-9_]+)*)@([A-Za-z0-9_]([-.][A-Za-z0-9_])*)+[.][A-Za-z0-9]{2,16}/.test(
    string
  );
}

export function matchPhoneFormat(string) {
  return /^\d{3}-\d{3}-\d{4}$/.test(string);
}

export function leadingOrTrailingSpaces(string) {
  return string !== string.trim();
}

export function hasSingleQuote(string) {
  return /[`']/.test(string);
}

export function containsOnlyAsciiChars(string) {
  return /^[\000-\177]*$/.test(string);
}

export const phoneNumberAutoFormat = (phoneNumberString) => {
  const number = phoneNumberString.trim().replace(/[^0-9]/g, "");

  if (number.length < 4) return number;
  if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
  if (number.length < 11)
    return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
  return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
};