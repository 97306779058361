import React from "react";
// Material-UI imports
import { Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function Loading({ isLoading = true }) {
  const classes = useStyles();
  if (isLoading) {
    return (
      <div className={classes.loading}>
        <Box component="div" visibility="hidden">
          Loading...
        </Box>
        <CircularProgress size={24} className={classes.buttonProgress} />
      </div>
    );
  }
  return null;
}

const useStyles = makeStyles(theme => ({
  loading: {
    position: "relative",
    width: "100%",
    height: "100%",
    zIndex: 1000
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));
