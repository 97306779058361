export default function getBrandingConfig() {
  const hostname = window.location.hostname;
  if (hostname.indexOf("paysmith") !== -1) {
    return "paysmith";
  } else if (hostname.indexOf("paymentconcepts") !== -1) {
    return "paymentconcepts";
  } else if (hostname.indexOf("trans2pay") !== -1) {
    return "trans2pay";
  } else if (hostname.indexOf("globalclientsolutions") !== -1) {
    return "globalclientsolutions";
  } else {
    return "globalholdings";
  }
}
