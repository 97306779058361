import React, { Fragment, useState, useEffect } from "react";
// Custom imports
import { fetchDebtSettlementApi } from "../../services/FetchApi";
import getApiEnvironmentConfig from "../../helpers/GetApiEnvironmentConfig";

export default function FeatureFlag(props) {
  const [flagStatus, setFlagStatus] = useState(false);

  const fetchFeatureFlag = () => {
    const api = `${getApiEnvironmentConfig()}/featureflags/${props.flagName}`;
    fetchDebtSettlementApi("GET", api)
      .then((response) => {
        if (response.status === 200) {
          const flagsForCurrentApp = response.data.filter((item) => {
            return item.appName === process.env.REACT_APP_CLIENT_ID;
          });
          if (flagsForCurrentApp[0].flagValue === "true") {
            setFlagStatus(true);
          }
        } else {
          //fail silently
        }
      })
      .catch((error) => {
        //fail silently
      });
  };

  useEffect(() => {
    fetchFeatureFlag();
  });

  return flagStatus && <Fragment>{props.children}</Fragment>;
}
