import axios from "axios";
import qs from "qs";

import getApiEnvironmentConfig from "../../helpers/GetApiEnvironmentConfig";

export function logIn(response) {
  const {
    accessToken,
    accountIds,
    refreshToken,
    legacyToken,
    username,
    tokenIssueDate,
    tokenExpirationDate,
    isTempPassword,
    lockoutEnabled,
    authorizedApplications,
    isActive,
    isAdUser,
    twoFactorStatus,
    maskedPhone,
    maskedEmail,
    access_token,
    AccountId,
    expires,
    refresh_token,
    UserName,
    IsTempPassword,
    legacy_token,
  } = response.data;

  if (lockoutEnabled === undefined) {
    // aka old DebtSettlementAPI
    if (
      access_token &&
      AccountId &&
      expires &&
      refresh_token &&
      IsTempPassword &&
      UserName
    ) {
      sessionStorage.setItem("legacy_token", legacy_token);
      sessionStorage.setItem("access_token", access_token);
      sessionStorage.setItem("refresh_token", refresh_token);
      sessionStorage.setItem("AccountId", AccountId);
      sessionStorage.setItem("expires", expires);
      sessionStorage.setItem(
        "tempPass",
        IsTempPassword === "Y"
          ? JSON.stringify("true")
          : JSON.stringify("false")
      );
      sessionStorage.setItem("userName", UserName);
    } else {
      throw new Error(`Error Code ${response.status}`);
    }
  } else if (
    // new api Gateway
    accessToken &&
    accountIds &&
    tokenExpirationDate &&
    isTempPassword !== undefined &&
    username &&
    lockoutEnabled !== undefined &&
    isActive !== undefined &&
    twoFactorStatus !== undefined
  ) {
    sessionStorage.setItem("access_token", accessToken);
    sessionStorage.setItem("refresh_token", refreshToken);
    sessionStorage.setItem("legacy_token", legacyToken);
    sessionStorage.setItem("AccountId", accountIds);
    sessionStorage.setItem("expires", tokenExpirationDate);
    sessionStorage.setItem("tempPass", JSON.stringify(isTempPassword));
    sessionStorage.setItem("userName", username);
    sessionStorage.setItem("lockoutEnabled", lockoutEnabled);
    sessionStorage.setItem("isActive", isActive);
    sessionStorage.setItem("twoFactorStatus", twoFactorStatus);
    sessionStorage.setItem("tokenIssueDate", tokenIssueDate);
    sessionStorage.setItem("isAdUser", isAdUser);
    sessionStorage.setItem("maskedPhone", maskedPhone);
    sessionStorage.setItem("maskedEmail", maskedEmail);
    sessionStorage.setItem("authorizedApplications", authorizedApplications);
  } else {
    throw new Error(`Error Code ${response.status}`);
  }
}

export function logOut() {
  sessionStorage.clear();
  sessionStorage.setItem("requestedUrl", window.location.pathname);
  window.location.assign("/");
}

export function userIsAuthenticated() {
  if (
    sessionStorage.getItem("legacy_token") &&
    sessionStorage.getItem("refresh_token") &&
    sessionStorage.getItem("AccountId") &&
    sessionStorage.getItem("expires") &&
    sessionStorage.getItem("access_token")
  ) {
    startSessionTimer();
    return true;
  }
  return false;
}

export function passwordIsTemporary() {
  if (JSON.parse(sessionStorage.getItem("tempPass")) === true) {
    return true;
  }
  return false;
}

export function startSessionTimer() {
  // Start a timer
  let sessionTimer = window.setInterval(logOut, 5 * 60 * 1000);
  // Function used to refresh the timer
  const refreshSession = function () {
    clearInterval(sessionTimer);
    sessionTimer = window.setInterval(logOut, 5 * 60 * 1000);
  };
  // If user clicks something or presses a key reset the timer
  document.addEventListener("click", refreshSession);
  document.addEventListener("keydown", refreshSession);
  document.addEventListener("mousemove", refreshSession);
  document.addEventListener("scroll", refreshSession);
}

export function createResponseInterceptor() {
  const interceptor = axios.interceptors.response.use(
    // If the request succeeds, return the original response
    (response) => {
      return response;
    },
    // If there is an error, use the Interceptor to handle the error.
    (error) => {
      const status = error.response ? error.response.status : null;
      // Reject the Promise if the error is not a 401 error.
      if (status !== 401) {
        return Promise.reject(error);
        // When the response code is a 401, try to refresh the Bearer token.
      } else {
        // Eject the interceptor so it doesn't loop in case token refresh causes the 401 response
        axios.interceptors.response.eject(interceptor);
        // Post the refresh token to the API in order to receive a new Bearer token.
        const api = `${getApiEnvironmentConfig()}/token`;
        return axios({
          method: "POST",
          url: api,
          withCredentials: true,
          crossdomain: true,
          data: qs.stringify({
            grant_type: "refresh_token",
            refresh_token: sessionStorage.getItem("refresh_token"),
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
          }),
        })
          .then((response) => {
            // Save the new tokens and resolve the original Promise as true
            const { access_token, expires, refresh_token } = response.data;
            sessionStorage.setItem("legacy_token", access_token);
            sessionStorage.setItem("refresh_token", refresh_token);
            sessionStorage.setItem("expires", expires);
            return Promise.resolve(true);
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
    }
  );
}

export function MapMfaData(response) {
  let result;
  if (response) {
    const data = response.data;
    result = {
      maskedPhone: data.maskedPhone,
      phoneType: data.phoneType,
      phoneConfirmed: data.phoneConfirmed,
      maskedEmail: data.maskedEmail,
      emailConfirmed: data.emailConfirmed,
      twoFactorStatus: data.twoFactorStatus,
    };
    return result;
  }
  throw new Error("Response was empty.");
}
