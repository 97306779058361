import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";

// Custom imports
import Loading from "../../components/Loading";
import PrivateRoute from "../../components/PrivateRoute";
import Layout from "../../containers/Layout";
import { createResponseInterceptor } from "../../services/Authentication";
import ChangeTempPasswordForm from "../../components/ChangeTempPasswordForm";
import Deposit from "../../components/Deposits";
import DepsoitComplete from "../../components/DepositComplete";
import FeatureFlag from "../../components/FeatureFlag";

const Login = lazy(() => import("../../components/Login"));
const Overview = lazy(() => import("../Overview"));
const ScheduledFutureTransactions = lazy(() =>
  import("../ScheduledFutureTransactions")
);
const ProcessedTransactions = lazy(() => import("../ProcessedTransactions"));
const SecurityQuestions = lazy(() =>
  import("../../components/SecurityQuestions")
);
const Faq = lazy(() => import("../Faq"));
const AccountSettings = lazy(() => import("../AccountSettings"));
const CustomerSupport = lazy(() => import("../CustomerSupport"));
const LoginFaq = lazy(() => import("../LoginFaq"));
const CreateUser = lazy(() => import("../CreateUser"));
const Statements = lazy(() => import("../Statements"));
const DocumentUploads = lazy(() => import("../DocumentUploads"));

export default function App() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  createResponseInterceptor();

  return (
    <Suspense fallback={<Loading isLoading={true} />}>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/createuser" component={() => <CreateUser />} />
            <PrivateRoute
              exact
              path="/overview"
              component={() => (
                <SecurityQuestions securedComponent={<Overview />} />
              )}
              temporary={() => <ChangeTempPasswordForm />}
            />
            <Route exact path="/loginfaqs" component={() => <LoginFaq />} />
            <PrivateRoute
              exact
              path="/overview"
              component={() => (
                <SecurityQuestions securedComponent={<Overview />} />
              )}
              temporary={() => <ChangeTempPasswordForm />}
            />
            <PrivateRoute
              exact
              path="/transactions/scheduled"
              component={() => (
                <SecurityQuestions
                  securedComponent={<ScheduledFutureTransactions />}
                />
              )}
              temporary={() => <ChangeTempPasswordForm />}
            />
            <PrivateRoute
              exact
              path="/transactions/completed"
              component={() => (
                <SecurityQuestions
                  securedComponent={<ProcessedTransactions />}
                />
              )}
              temporary={() => <ChangeTempPasswordForm />}
            />
            <PrivateRoute
              exact
              path="/faq"
              component={() => <SecurityQuestions securedComponent={<Faq />} />}
            />
            <PrivateRoute
              exact
              path="/account-settings"
              component={() => (
                <SecurityQuestions securedComponent={<AccountSettings />} />
              )}
              temporary={() => <ChangeTempPasswordForm />}
            />
            <PrivateRoute
              exact
              path="/customer-support"
              component={() => (
                <SecurityQuestions securedComponent={<CustomerSupport />} />
              )}
              temporary={() => <ChangeTempPasswordForm />}
            />
            <PrivateRoute
              exact
              path="/statements"
              component={() => (
                <SecurityQuestions securedComponent={<Statements />} />
              )}
              temporary={() => <ChangeTempPasswordForm />}
            />
            <PrivateRoute
              exact
              path="/document-uploads"
              component={() => (
                <FeatureFlag flagName="DocumentUploadsEnabled">
                  <SecurityQuestions securedComponent={<DocumentUploads />} />
                </FeatureFlag>
              )}
              temporary={() => <ChangeTempPasswordForm />}
            />
            <Route exact path="/deposit" component={() => <Deposit />} />
            <Route
              exact
              path="/depositcomplete"
              component={() => <DepsoitComplete />}
            />
            <Route
              exact
              path="/"
              component={() => (
                <Login forgotPassword={params["forgotPassword"] === "true"} />
              )}
            />
            <Redirect to="/" />
          </Switch>
        </Layout>
      </Router>
    </Suspense>
  );
}
