import React, { lazy, memo, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
// Material-UI imports
import {
  AppBar,
  Paper,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Hidden,
  Drawer
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Material-UI icon imports
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
// Custom imports
import { logOut } from "../../services/Authentication";
import { BrandingContext } from "../../context/Branding";
import { MemoizedNavigation as Navigation } from "../Navigation";

const Welcome = lazy(() => import("../Welcome"));

function Header(props) {
  const classes = useStyles();
  const brand = useContext(BrandingContext);
  const logoSrc = `/${brand.brandingIdentifier}/${brand.logoFilePath}`;
  const [anchorElement, setAnchorElement] = useState(null);
  const [open, setOpen] = useState(false);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawer = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleClick = event => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Paper elevation={1} className="zIndex100">
        <Toolbar>
          <NavLink className={classes.homeLink} to="/overview">
            <img className={classes.logo} src={logoSrc} alt={brand.brandName} />
          </NavLink>
          <Hidden mdUp>
            <Button
              color="primary"
              aria-label="open drawer"
              aria-controls="navigation-menu"
              onClick={handleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuOutlinedIcon />
            </Button>
          </Hidden>
          <Button
            aria-controls="user-menu"
            aria-haspopup="true"
            aria-label="open profile menu"
            className={classes.menuButton}
            color="primary"
            onClick={handleClick}
          >
            <Hidden smDown>
              <Welcome />
              <KeyboardArrowDownOutlinedIcon />
            </Hidden>
            <Hidden mdUp>
              <MoreHorizOutlinedIcon />
            </Hidden>
          </Button>
          <Menu
            id="user-menu"
            elevation={1}
            anchorEl={anchorElement}
            keepMounted
            open={Boolean(anchorElement)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            MenuListProps={{
              disablePadding: true
            }}
          >
            <MenuItem className={classes.mobileWelcome}>
              <Welcome />
            </MenuItem>
            <MenuItem disableGutters>
              <NavLink
                className={classes.menuLink}
                to="/account-settings"
                onClick={handleClose}
              >
                <AccountCircleOutlinedIcon className={classes.icon} />
                Account Settings
              </NavLink>
            </MenuItem>
            <MenuItem disableGutters>
              <NavLink
                className={classes.menuLink}
                to="/faq"
                onClick={handleClose}
              >
                <ContactSupportOutlinedIcon className={classes.icon} />
                FAQ
              </NavLink>
            </MenuItem>
            <MenuItem disableGutters>
              <NavLink
                className={classes.menuLink}
                to="/customer-support"
                onClick={handleClose}
              >
                <EmailOutlinedIcon className={classes.icon} />
                Customer Support
              </NavLink>
            </MenuItem>

            <Button
              onClick={logOut}
              variant="outlined"
              color="primary"
              className={classes.logout}
            >
              Log Out
            </Button>
          </Menu>
        </Toolbar>
        <Hidden mdUp>
          <Drawer
            className={classes.drawer}
            anchor="left"
            open={open}
            onClose={handleDrawerClose}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <div className={classes.drawerHeader}>
              <Button
                aria-controls="navigation-menu"
                aria-label="close navigation menu"
                className={classes.menuButton}
                color="primary"
                onClick={handleDrawerClose}
              >
                <ChevronLeftOutlinedIcon />
              </Button>
            </div>
            <Navigation id="navigation-menu" />
          </Drawer>
        </Hidden>
      </Paper>
    </AppBar>
  );
}

const useStyles = makeStyles(theme => ({
  menuButton: {
    height: 80,
    padding: theme.spacing(0, 2),
    borderRadius: 0
  },
  homeLink: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center"
  },
  menuLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    display: "flex",
    width: "100%",
    alignItems: "center",
    padding: theme.spacing(1, 2)
  },
  logout: {
    margin: theme.spacing(2, 4)
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  logo: {
    maxWidth: 150
  },
  mobileWelcome: {
    borderBottom: "1px solid #eee",
    "&:hover": {
      backgroundColor: "#fff"
    }
  },
  drawer: {
    width: 200
  },
  drawerHeader: {
    borderBottom: "1px solid #eee"
  },
  drawerPaper: {
    width: 200
  },
  "@media (min-width: 980px)": {
    mobileWelcome: {
      display: "none"
    }
  }
}));

export const MemoizedHeader = memo(Header);
