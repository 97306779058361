import React, { memo, useContext } from "react";
// Material-UI imports
import { Paper, Link, Typography } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
// Custom imports
import { getThisYear } from "../../helpers/FormatDates";
import { BrandingContext } from "../../context/Branding";

function Footer() {
  const classes = useStyles();
  const brand = useContext(BrandingContext);

  return (
    <Paper elevation={0}>
      <Typography>
        <small className={classes.copyright}>
          &copy; {getThisYear()}{" "}
          <Link href={brand.brandWebsiteUrl} component="a" target="_blank">
            {brand.brandName}
          </Link>
        </small>
      </Typography>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  copyright: {
    lineHeight: `${theme.spacing(3)}px`
  }
}));

export const MemoizedFooter = memo(Footer);
